<template>
    <!--
    Страница согласия с обработкой данных
    -->
    <el-container class="wrapper_policy">
        <el-card class="policy-card">
            <div slot="header" class="clearfix">
                <span style="font-size: 20px; font-weight: bold">Политика обработки персональных данных</span>
                <el-button style="float: right; padding: 3px 0" type="text">
                    <router-link to="/" class="logout_button">
                        Назад
                    </router-link>
                </el-button>
            </div>
            <el-container direction="vertical">
                <div class="rules-list">
                    <p style="padding: 20px 0; text-align: center">ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ЯКОВЛЕВ ЛЕГАЛТЕХ»</p>
                    <p style="text-align: center">101000, город Москва, Большой Казённый пер., д. 2, ОГРН 1207700286067, ИНН 9709064392</p>
                    <div style="display: flex; align-items: center; justify-content: center; margin-top: 50px;">
                        <div style="text-align: center; font-weight: bold; font-size: 20px;">
                            Политика обработки персональных данных
                        </div>
                    </div>
                    <div style="display: flex; justify-content: space-around; margin-top: 50px;">
                        <div>г. Москва</div>
                        <div>21 сентября 2021 г.</div>
                    </div>

                    <ol style="margin-top: 50px;">
                        <li>
                            <p class="rules-list__first">ОБЩИЕ ПОЛОЖЕНИЯ</p>
                            <ol>
                                <li>
                                    Политика обработки персональных данных (далее - Политика) издана и применяется ООО "ЯКОВЛЕВ ЛЕГАЛТЕХ" (далее - Оператор) в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных". Настоящая Политика определяет политику, порядок и условия Оператора в отношении обработки персональных данных, устанавливает процедуры,
                                    направленные на предотвращение и выявление нарушений законодательства Российской Федерации, устранение последствий таких нарушений, связанных с обработкой персональных данных. Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящей Политикой, разрешаются в соответствии с действующим законодательством Российской Федерации в области
                                    персональных данных.
                                </li>
                                <li>
                                    Целью обработки персональных данных является:
                                    <dl style="padding-left: 30px;">
                                        <dt>обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну;</dt>
                                        <dt>продвижение товаров, работ, услуг Оператора на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи (допускается в порядке, предусмотренном п. 3.7 настоящей Политики);</dt>
                                        <dt>организация, подготовка и проведения общих собраний собственников многоквартирных домов в соответствии с действующим законодательством;</dt>
                                        <dt>опубликование результатов (решений, документов) общих собраний собственников многоквартирных домов в порядке, установленном с действующим законодательством.</dt>
                                    </dl>
                                </li>
                                <li>
                                    Действие настоящей Политики не распространяется на отношения, возникающие при:
                                    <div style="padding-left: 30px;">
                                        <p>1) организации хранения, комплектования, учета и использования содержащих персональные данные документов Архивного фонда Российской Федерации и других архивных фондов;</p>
                                        <p>2) обработке персональных данных, отнесенных в установленном порядке к сведениям, составляющим государственную тайну;</p>
                                        <p>3) предоставлении уполномоченными органами информации о деятельности судов в Российской Федерации в соответствии с Федеральным законом от 22.12.2008 N 262-ФЗ "Об обеспечении доступа к информации о деятельности судов в Российской Федерации".</p>
                                    </div>
                                </li>
                                <li>
                                    Обработка организована Оператором на принципах:
                                    <dl style="padding-left: 30px;">
                                        <dt>законности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;</dt>
                                        <dt>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</dt>
                                        <dt>обработки только персональных данных, которые отвечают целям их обработки;</dt>
                                        <dt>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;</dt>
                                        <dt>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;</dt>
                                        <dt>обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;</dt>
                                        <dt>хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.</dt>
                                        <dt>организация, подготовка и проведения общих собраний собственников многоквартирных домов в соответствии с действующим законодательством;</dt>
                                        <dt>опубликование результатов (решений, документов) общих собраний собственников многоквартирных домов в порядке, установленном с действующим законодательством.</dt>
                                    </dl>
                                </li>
                                <li>Обработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных" и настоящей Политикой.</li>
                                <li>
                                    Способы обработки персональных данных:
                                    <dl style="padding-left: 30px;">
                                        <dt>с использованием средств автоматизации;</dt>
                                        <dt>без использования средств автоматизации.</dt>
                                    </dl>
                                </li>
                                <li>
                                    Категории персональных данных. В информационных системах Оператора осуществляется обработка следующих категорий персональных данных:
                                    <div style="padding-left: 30px;">
                                        <p>1) категория 1: Ф.И.О. субъекта персональных данных;</p>
                                        <p>2) категория 2: Паспортные данные субъекта персональных данных (в случаях, предусмотренных законодательством);</p>
                                        <p>3) категория 3: Адрес квартиры и/или нежилого помещения субъекта персональных данных (в случаях, предусмотренных законодательством);</p>
                                        <p>4) категория 4: Реестр собственников помещений в многоквартирных домах (в случаях, предусмотренных законодательством);</p>
                                        <p>5) категория 5: Контактный телефон, факс (при наличии) субъекта персональных данных (в случаях, предусмотренных законодательством);</p>
                                        <p>6) категория 6: Адрес электронной почты субъекта персональных данных (в случаях, предусмотренных законодательством);</p>
                                        <p>7) категория 7: Иные персональные данные, необходимые для подготовки и проведения общих собраний собственников в многократных домах в объеме, предусмотренном законодательством.</p>
                                    </div>
                                </li>
                                <li>В соответствии с поставленными целями и задачами Оператор до начала обработки персональных данных назначает ответственного за организацию обработки персональных данных в должности не ниже начальника структурного подразделения (или: заместителя руководителя Оператора), именуемого далее "куратор ОПД".
                                    <ol style="margin-top: 7px;">
                                        <li>Куратор ОПД получает указания непосредственно от исполнительного органа Оператора и подотчетен ему.</li>
                                        <li>Куратор вправе оформлять и подписывать уведомление, предусмотренное ч. 1 и 3 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
                                    </ol>
                                </li>
                                <li>Настоящая Политика и изменения к ней утверждаются Руководителем Оператора и вводятся приказом Оператора.</li>
                                <li>Сотрудники Оператора, непосредственно осуществляющие обработку персональных данных, должны быть ознакомлены под роспись до начала работы с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, документами, определяющими политику Оператора в отношении обработки персональных данных,
                                    локальными актами по вопросам обработки персональных данных, с данной Политикой и изменениями к ней. Обучение указанных работников организуется структурным подразделением по повышению квалификации в соответствии с утвержденными Оператором графиками.
                                </li>
                                <li>При обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
                                <li>Режим конфиденциальности персональных данных Оператор обеспечивает в соответствии с Положением Оператора о конфиденциальности.</li>
                                <li>Контроль за соблюдением сотрудниками Оператора требований законодательства Российской Федерации и положений локальных актов Оператора организован в соответствии с Положением о внутреннем контроле Оператора при обработке персональных данных. Контроль заключается в проверке выполнения требований нормативных документов по защите информации, а также в оценке
                                    обоснованности и эффективности принятых мер. Он может проводиться структурным подразделением, ответственным за обеспечение безопасности персональных данных, или на договорной основе сторонними организациями, имеющими лицензии на деятельность по технической защите конфиденциальной информации.
                                </li>
                                <li>Аудит соблюдения Оператором требований законодательства Российской Федерации и положений локальных нормативных актов Оператора организован в соответствии с Положением Оператора об аудите при обработке персональных данных.</li>
                                <li>Оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения Оператором требований Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса Российской Федерации. Соотношение указанного вреда и принимаемых Оператором мер, направленных на
                                    предупреждение, недопущение и/или устранение его последствий, установлено в Положении о недопущении Оператором вреда при обработке персональных данных.
                                </li>
                                <li>Опубликование или обеспечение иным образом неограниченного доступа к настоящей Политике, иным документам, определяющим политику Оператора в отношении обработки персональных данных, к сведениям о реализуемых требованиях к защите персональных данных Оператор проводит в соответствии с Положением Оператора о раскрытии информации.</li>
                                <li>При осуществлении сбора персональных данных с использованием информационно-телекоммуникационных сетей Оператор до начала обработки персональных данных обязан опубликовать в соответствующей информационно-телекоммуникационной сети документ, определяющий его политику в отношении обработки персональных данных, и сведения о реализуемых требованиях к защите
                                    персональных данных, а также обеспечить возможность доступа к указанному документу с использованием средств соответствующей информационно-телекоммуникационной сети.
                                </li>
                                <li>Оператор обязан представить документы и локальные акты, указанные в ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", и (или) иным образом подтвердить принятие мер, указанных в ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", по запросу уполномоченного органа по защите прав субъектов персональных
                                    данных в течение 20 (Двадцати) дней.
                                </li>
                                <li>Условия обработки персональных данных Оператором:
                                    <div style="padding-left: 30px;">
                                        <p>1) обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</p>
                                        <p>2) обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;</p>
                                        <p>3) обработка персональных данных осуществляется в связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве, судопроизводстве в арбитражных судах;</p>
                                        <p>4) обработка персональных данных необходима для исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;</p>
                                        <p>5) обработка персональных данных необходима для исполнения полномочий федеральных органов исполнительной власти, органов государственных внебюджетных фондов, исполнительных органов государственной власти субъектов Российской Федерации, органов местного самоуправления и функций организаций, участвующих в предоставлении соответственно государственных и
                                            муниципальных услуг, предусмотренных Федеральным законом от 27.07.2010 N 210-ФЗ "Об организации предоставления государственных и муниципальных услуг", включая регистрацию субъекта персональных данных на едином портале государственных и муниципальных услуг и (или) региональных порталах государственных и муниципальных услуг;</p>
                                        <p>6) обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или
                                            поручителем;</p>
                                        <p>7) обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;</p>
                                        <p>8) обработка персональных данных необходима для осуществления прав и законных интересов оператора или третьих лиц, в том числе в случаях, предусмотренных Федеральным законом от 03.07.2016 N 230-ФЗ "О защите прав и законных интересов физических лиц при осуществлении деятельности по возврату просроченной задолженности и о внесении изменений в 5 Федеральный
                                            закон "О микрофинансовой деятельности и микрофинансовых организациях", либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</p>
                                        <p>9) обработка персональных данных необходима для осуществления профессиональной деятельности журналиста и (или) законной деятельности средства массовой информации либо научной, литературной или иной творческой деятельности при условии, что при этом не нарушаются права и законные интересы субъекта персональных данных;</p>
                                        <p>10) обработка персональных данных осуществляется в статистических или иных исследовательских целях, за исключением целей, указанных в ст. 15 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", при условии обязательного обезличивания персональных данных;</p>
                                        <p>11) обработка персональных данных, полученных в результате обезличивания персональных данных, осуществляется в целях повышения эффективности государственного или муниципального управления, а также в иных целях, предусмотренных Федеральным законом от 24 апреля 2020 года N 123-ФЗ "О проведении эксперимента по установлению специального регулирования в целях
                                            создания необходимых условий для разработки и внедрения технологий искусственного интеллекта в субъекте Российской Федерации - городе федерального значения Москве и внесении изменений в ст. ст. 6 и 10 Федерального закона "О персональных данных", в порядке и на условиях, которые предусмотрены Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных
                                            данных", и Федеральным законом от 31 июля 2020 года N 258-ФЗ "Об экспериментальных правовых режимах в сфере цифровых инноваций в Российской Федерации", в порядке и на условиях, которые предусмотрены указанными федеральными законами; 12) осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с
                                            федеральным законом.</p>
                                    </div>
                                </li>
                                <li>Оператор на основании договора может поручить обработку персональных данных третьему лицу. Существенным условием такого договора является наличие права у данного лица на обработку персональных данных, обязанность обеспечения указанным лицом конфиденциальности персональных данных и безопасности персональных данных при их обработке.</li>
                                <li>Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели их обработки, и они подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в их достижении в порядке, предусмотренном Положением о хранении персональных данных у Оператора.</li>
                                <li>Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.</li>
                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first"> Структурные подразделения оператора по обработке персональных данных </p>
                            <ol>
                                <li>Обработку персональных данных организует Руководитель Оператора.</li>
                                <li>Руководитель Оператора:
                                    <div style="padding-left: 30px;">
                                        <p>1) доводит до сведения работников Оператора положения законодательства Российской Федерации о персональных данных, локальных актов по вопросам обработки персональных данных, требований к защите персональных данных;</p>
                                        <p>2) организует обработку персональных данных сотрудниками Оператора;</p>
                                        <p>3) организует прием и обработку обращений и запросов субъектов персональных данных или их представителей.</p>
                                        <p>4) осуществляет внутренний контроль за соблюдением Оператором и его работниками законодательства Российской Федерации о персональных данных, в том числе требований к защите персональных данных;</p>
                                        <p>5) контролирует прием и обработку обращений и запросов субъектов персональных данных или их представителей.</p>
                                    </div>
                                </li>
                                <li>

                                    Сотруднику Оператора, имеющему право осуществлять обработку ПД, предоставляются уникальный логин и пароль для доступа к соответствующей информационной системе Оператора в установленном порядке.
                                    Доступ предоставляется к прикладным программным подсистемам в соответствии с функциями, предусмотренными должностными регламентами Оператора.
                                    <br><br>
                                    Информация может вноситься как в автоматическом режиме - при уточнении, извлечении, использовании и передаче на машиночитаемом носителе информации, так и в ручном режиме - при получении информации на бумажном носителе или в ином виде, не позволяющем осуществлять ее автоматическую регистрацию.
                                </li>
                                <li>Обеспечение безопасности ПД, обрабатываемых в информационных системах Оператора, достигается путем исключения несанкционированного, в том числе случайного, доступа к ПД, а также принятия следующих мер по обеспечению безопасности:
                                    <dl style="padding-left: 30px;">
                                        <dt>определение актуальных угроз безопасности ПД и информационных технологий, используемых в информационных системах;</dt>
                                        <dt>применение организационных и технических мер по обеспечению безопасности ПД при их обработке в информационных системах Оператора, необходимых для выполнения требований к защите ПД данных, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности ПД;</dt>
                                        <dt>применение процедур оценки соответствия средств защиты информации;</dt>
                                        <dt>оценка эффективности принимаемых мер по обеспечению безопасности ПД до ввода в эксплуатацию информационной системы;</dt>
                                        <dt>учет машинных носителей ПД;</dt>
                                        <dt>обеспечение работоспособного функционирования компьютерной техники с ПД в соответствии с эксплуатационной и технической документацией компьютерной техники и с учетом технических требований информационных систем и средств защиты информации;</dt>
                                        <dt>обнаружение и регистрация фактов несанкционированного доступа к ПД, несанкционированной повторной и дополнительной записи информации после ее извлечения из информационной системы ПД и принятие мер;</dt>
                                        <dt>восстановление ПД, модифицированных или удаленных, уничтоженных вследствие несанкционированного доступа к ним;</dt>
                                        <dt>установление правил доступа к ПД, обрабатываемым в информационных системах Оператора, а также обеспечение регистрации и учета всех действий, совершаемых с ПД в информационных системах Оператора;</dt>
                                        <dt>контроль за принимаемыми мерами по обеспечению безопасности ПД и уровней защищенности информационных систем.</dt>
                                    </dl>
                                </li>
                                <li>Руководитель обеспечивает:
                                    <dl style="padding-left: 30px;">
                                        <dt>своевременное обнаружение фактов несанкционированного доступа к ПД и немедленное доведение этой информации до ответственного за организацию обработки ПД;</dt>
                                        <dt>недопущение воздействия на технические средства автоматизированной обработки ПД, в результате которого может быть нарушено их функционирование;</dt>
                                        <dt>восстановление ПД, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</dt>
                                        <dt>постоянный контроль за обеспечением уровня защищенности ПД;</dt>
                                        <dt>соблюдение условий использования средств защиты информации, предусмотренных эксплуатационной и технической документацией;</dt>
                                        <dt>учет применяемых средств защиты информации, эксплуатационной и технической документации к ним, носителей ПД;</dt>
                                        <dt>при обнаружении нарушений порядка предоставления ПД незамедлительное приостановление предоставления ПД пользователям информационной системы ПД до выявления причин нарушений и устранения этих причин;</dt>
                                        <dt>разбирательство и составление заключений по фактам несоблюдения условий хранения материальных носителей ПД, использования средств защиты информации, которые могут привести к нарушению конфиденциальности ПД или другим нарушениям, приводящим к снижению уровня защищенности ПД, разработка и принятие мер по предотвращению возможных опасных последствий
                                            подобных нарушений.
                                        </dt>
                                    </dl>
                                </li>
                                <li>Руководитель принимает все необходимые меры по восстановлению ПД, модифицированных или удаленных, уничтоженных вследствие несанкционированного доступа к ним.</li>
                                <li>Обмен ПД при их обработке в информационных системах Оператора осуществляется по каналам связи, защита которых обеспечивается путем реализации соответствующих организационных мер и путем применения программных и технических средств.</li>
                                <li>Доступ сотрудников Оператора к ПД, находящимся в информационных системах Оператора, предусматривает обязательное прохождение процедуры идентификации и аутентификации.</li>
                                <li>В случае выявления нарушений порядка обработки ПД в информационных системах Оператора уполномоченными должностными лицами незамедлительно принимаются меры по установлению причин нарушений и их устранению.</li>

                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first">Порядок обеспечения оператором прав субъекта персональных данных</p>
                            <ol>
                                <li>Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных" и другими нормативно-правовыми актами, регламентирующими обработку персональных данных.</li>
                                <li>Оператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
                                <li>Полномочия представителя на представление интересов каждого субъекта персональных данных подтверждаются доверенностью, оформленной в порядке ст. ст. 185 и 185.1 Гражданского кодекса Российской Федерации, ч. 2 ст. 53 Гражданского процессуального кодекса Российской Федерации или удостоверенной нотариально согласно ст. 59 Основ законодательства Российской Федерации
                                    о нотариате. Копия доверенности представителя, отснятая Службой ОПД с оригинала, хранится Оператором не менее трех лет, а в случае, если срок хранения персональных данных больше трех лет, - не менее срока хранения персональных данных.
                                </li>
                                <li>Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", предоставляются субъекту персональных данных Службой ОПД в доступной форме без персональных данных, относящихся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных, в электронном
                                    виде. По требованию субъекта персональных данных они могут быть продублированы на бумаге. Доступная форма заверяется куратором ОПД или иным уполномоченным приказом руководителя Оператора сотрудником Службы ОПД.
                                </li>
                                <li>Сведения, указанные в ч. 7 ст. 22 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", предоставляются субъекту персональных данных или его представителю при личном обращении либо при получении запроса субъекта персональных данных или его представителя. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта
                                    персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки
                                    персональных данных Оператором, подпись субъекта персональных данных или его представителя. При наличии технической возможности запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.
                                </li>
                                <li>Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</li>
                                <li>Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи, а также в целях политической агитации допускается только при условии предварительного согласия субъекта персональных данных. Согласие может быть устным или письменным.
                                    <ol style="margin-top: 7px;">
                                        <li>Индивидуальное устное общение с потенциальными потребителями или агитируемыми лицами производится по специально выделенной телефонной линии Оператора. При этом рабочее место сотрудника Оператора, которому поручено общение, обеспечивается техническими средствами, позволяющими в автоматизированном режиме вести регистрацию телефонных вызовов, а также (с
                                            согласия субъекта персональных данных) вести аудиозапись переговоров. В данной ситуации аудиозапись полученного устного согласия является надлежащей.
                                        </li>
                                        <li>Если документирование информации в виде аудиозаписи на цифровой диктофон или аудиокассету проводилось физическим лицом по собственной инициативе скрытно, а порой с целью искусственного создания доказательств, то данные доказательства признаются недопустимыми и не имеющими юридической силы на основании ч. 2 ст. 50 Конституции Российской Федерации.</li>
                                        <li>Для письменного согласия достаточно простой письменной формы. Указанная обработка персональных данных признается осуществляемой с предварительного согласия субъекта персональных данных, если субъект персональных данных не докажет, что такое согласие не было получено</li>
                                    </ol>
                                </li>
                                <li>Оператор обязан немедленно прекратить по требованию субъекта персональных данных обработку его персональных данных, указанную в ч. 1 ст. 15 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных".</li>
                                <li>Решение, порождающее юридические последствия в отношении субъекта персональных данных или иным образом затрагивающее его права и законные интересы, может быть принято на основании исключительно автоматизированной обработки его персональных данных только при наличии согласия в письменной форме субъекта персональных данных или в случаях, предусмотренных
                                    федеральными законами Российской Федерации, устанавливающими также меры по обеспечению соблюдения прав и законных интересов субъекта персональных данных.
                                </li>
                                <li>Оператор обязан устно, а по письменному требованию субъекта персональных данных или его представителя - письменно, разъяснить субъекту персональных данных порядок принятия решения на основании исключительно автоматизированной обработки его персональных данных и возможные юридические последствия такого решения, предоставить возможность заявить возражение против
                                    такого решения, а также разъяснить порядок защиты субъектом персональных данных своих прав и законных интересов
                                    <ol style="margin-top: 7px;">
                                        <li>Текст устного разъяснения Оператор составляет в письменном виде до начала автоматизированной обработки персональных данных и хранит не менее 3 (трех) лет.</li>
                                        <li>В случае автоматизированной обработки персональных данных различными способами разъяснение готовится отдельно для каждого способа.</li>
                                    </ol>
                                </li>
                                <li>Оператор обязан рассмотреть возражение, указанное в ч. 3 ст. 16 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", относительно решения, вынесенного на основании исключительно автоматизированной обработки персональных данных в течение 30 (тридцати) дней со дня получения возражения. <br>
                                    Оператор уведомляет субъекта персональных данных о результатах рассмотрения возражения в течение 20 (Двадцати) дней.
                                </li>
                                <li>Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту своего расположения в рабочее время.</li>
                                <li>Оператор в течение 20 (Двадцати) дней с момента исправления или уничтожения персональных данных по требованию субъекта персональных данных или его представителя обязан уведомить его о внесенных изменениях и предпринятых мерах и принять разумные меры для уведомления третьих лиц, которым персональные данные этого субъекта были переданы.</li>
                                <li>При трансграничной передаче персональных данных их перевод на другие языки осуществляется в порядке, согласованном Оператором с иностранным контрагентом.</li>
                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first">Порядок обработки персональных данных</p>
                            <ol>
                                <li>Цель обработки персональных данных определяет Руководитель Оператора. Цель обработки ПД утверждается приказом Оператора.</li>
                                <li>На основании заданной цели куратор ОПД определяет задачи, сроки, способы и условия обработки персональных данных, перечень причастных и ответственных лиц. Такие задачи, сроки, способы, условия, лица утверждаются распоряжением Оператора.</li>
                                <li>Куратор ОПД обязан:
                                    <dl style="padding-left: 30px;">
                                        <dt>организовывать принятие правовых, организационных и технических мер для обеспечения защиты ПД, обрабатываемых Оператором, от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПД, а также от иных неправомерных действий в отношении ПД;</dt>
                                        <dt>осуществлять внутренний контроль за соблюдением его подчиненными требований законодательства Российской Федерации в области ПД, в том числе требований к защите ПД;</dt>
                                        <dt>доводить до сведения сотрудников Оператора положения законодательства Российской Федерации в области ПД, локальных актов по вопросам обработки ПД, требований к защите ПД;</dt>
                                        <dt>организовать прием и обработку обращений и запросов субъектов ПД или их представителей, а также осуществлять контроль за приемом и обработкой таких обращений и запросов;</dt>
                                        <dt>в случае нарушения требований к защите ПД принимать необходимые меры по восстановлению нарушенных прав субъектов ПД.</dt>
                                    </dl>
                                </li>
                                <li>Куратор ОПД вправе:
                                    <dl style="padding-left: 30px;">
                                        <dt>иметь доступ к информации, касающейся порученной ему обработки ПД и включающей:</dt>
                                        <dt>цели обработки ПД;</dt>
                                        <dt>категории обрабатываемых ПД;</dt>
                                        <dt>категории субъектов, персональные данные которых обрабатываются;</dt>
                                        <dt>правовые основания обработки ПД;</dt>
                                        <dt>перечень действий с персональными данными, общее описание используемых у Оператора способов обработки ПД;</dt>
                                        <dt>описание мер, предусмотренных ст. ст. 18.1 и 19 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных", в том числе сведения о наличии шифровальных (криптографических) средств и наименования этих средств;</dt>
                                        <dt>дату начала обработки ПД;</dt>
                                        <dt>срок или условия прекращения обработки ПД;</dt>
                                        <dt>сведения о наличии или об отсутствии трансграничной передачи ПД в процессе их обработки;</dt>
                                        <dt>сведения об обеспечении безопасности ПД в соответствии с требованиями к защите ПД, установленными Правительством Российской Федерации;</dt>
                                        <dt>привлекать к реализации мер, направленных на обеспечение безопасности ПД, иных сотрудников Оператора с возложением на них соответствующих обязанностей и закреплением ответственности.</dt>
                                    </dl>
                                </li>
                                <li>Обезличивание, блокирование, удаление, уничтожение персональных данных осуществляются по следующей процедуре:
                                    <ol style="margin-top: 7px;">
                                        <li>ежегодно осуществляется экспертиза ценности дел (документов), содержащих персональные данные, постоянного и временного сроков хранения. По результатам экспертизы ценности документов составляются описи дел постоянного, временного (свыше 10 (десяти) лет)
                                            хранения и по личному составу (включая описи электронных документов постоянного хранения) (далее - описи дел), а также акты о выделении к уничтожению документов (дел), не подлежащих хранению (включая акты о выделении электронных документов).
                                        </li>
                                        <li>Документы (дела), не подлежащие хранению и включенные в данные акты, уничтожаются в присутствии специальной комиссии, созданной специально для уничтожения документов.</li>
                                        <li>По окончании процедуры уничтожения структурным подразделением составляется акт об уничтожении документов, в учетных формах (номенклатурах дел, журналах) проставляется отметка об их уничтожении, пишется словами или проставляется штамп "Уничтожено. Акт (дата, N)", заверяется подписью членов специальной комиссии, гражданского служащего или работника,
                                            осуществляющего учет документов, содержащих персональные данные.
                                        </li>
                                        <li>Уничтожение выделенных документов на бумажных носителях осуществляется с помощью бумагорезательной машины путем измельчения документов на куски, гарантирующего невозможность восстановления текста.<br><br>
                                            Уничтожение по окончании срока обработки персональных данных на электронных носителях производится путем механического нарушения целостности носителя, не позволяющего произвести считывание или восстановление персональных данных, или удалением с электронных носителей методами и средствами гарантированного удаления остаточной информации.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first">Взаимодействие с другими операторами при обработке персональных данных с применением системы электронного взаимодействия</p>
                            <ol>
                                <li>На основании двух- и многосторонних соглашений Оператор осуществляет обработку ПД в рамках электронного информационного взаимодействия с применением системы электронного взаимодействия (далее - СЭВ).</li>
                                <li>По согласованным регламентам Оператор в рамках СЭВ на основании поступивших запросов направляет информацию, включающую персональные данные субъектов, обрабатываемые Службой ОПД.</li>
                                <li>По перечню, утвержденному приказом Оператора, Служба ОПД в рамках СЭВ вправе направить запросы о предоставлении информации, включающей персональные данные субъектов.</li>
                                <li>Прекращение действия соглашения с другим оператором является основанием для уничтожения Оператором обработанных в рамках такого соглашения ПД.</li>
                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first">Обязанности руководителя и сотрудников оператора</p>
                            <ol>
                                <li>
                                    Руководитель Оператора:
                                    <dl style="padding-left: 30px;">
                                        <dt>организует устранение выявленных нарушений законодательства Российской Федерации, нормативных правовых актов уполномоченного федерального органа исполнительной власти, внутренних документов Оператора, а также причин и условий, способствовавших совершению нарушения.</dt>
                                    </dl>
                                </li>
                                <li>
                                    Сотрудники Оператора:
                                    <dl style="padding-left: 30px;">
                                        <dt>оказывают содействие Руководителю в выполнении им своих обязанностей;</dt>
                                        <dt>незамедлительно доводят до сведения Руководителя сведения о предполагаемых
                                            12 нарушениях законодательства Российской Федерации, в том числе нормативных правовых актов уполномоченного федерального органа исполнительной власти, и внутренних документов Оператора другими сотрудниками Оператора или контрагентами Оператора.
                                        </dt>
                                    </dl>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p class="rules-list__first">Контроль, ответственность за нарушение или неисполнение Политики</p>
                            <ol>
                                <li>Контроль за исполнением Политики возложен на Руководителя.</li>
                                <li>Лица, нарушающие или не исполняющие требования Положения, привлекаются к дисциплинарной, административной (ст. ст. 5.39, 13.11 - 13.14, ст. 19.7 Кодекса Российской Федерации об административных правонарушениях) или уголовной ответственности (ст. ст. 137, 140, 272 Уголовного кодекса Российской Федерации).</li>
                                <li>Руководители структурных подразделений Оператора несут персональную ответственность за исполнение обязанностей их подчиненными.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </el-container>
        </el-card>

    </el-container>
</template>

<script>
export default {
    name: "PrivacyPolicy"
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.privacy_row {
    margin-top: 15px;
}

.rules-list {

    dt::before {
        content: "- ";
        margin: 5px;
    }

    dt {
        margin: 10px 0;
    }

    ol {
        counter-reset: item;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .rules-list__first {
        display: inline-block;
        margin: 10px 0;
        font-weight: 700;
        font-size: 18px;
        text-transform: uppercase;
    }

    ol > li {
        margin-bottom: 10px;
    }

    ol > li:before {
        counter-increment: item;
        content: counters(item, '.') '. ';
        font-weight: 700;
    }
}


.wrapper_policy {
    min-height: 100vh;
    padding: 40px;

    .policy-card {
        margin: 0 auto;
        width: 80%;
    }

    .logout_button {
        color: $dom-light-blue;
        text-decoration: none;
        font-size: 16px;
    }

    .logout_button:hover {
        text-decoration: underline;
    }

    .el-header {
        padding: 0;
    }
}
</style>
